import React, { forwardRef } from "react";
import { ToggleButton, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface CustomToggleButtonProps {
  id?: string;
  value: any;
  selected: boolean;
  children: React.ReactNode;
  handleClick: (event: React.MouseEvent<HTMLElement>, value: any) => void;
  sx?: any;
  tooltip?: string;
  hidden?: boolean;
}

const CustomToggleButton = forwardRef<
  HTMLButtonElement,
  CustomToggleButtonProps
>(({ id, selected, value, handleClick, children, sx, tooltip, hidden }, ref) => {
  const theme = useTheme();
  
  if (hidden) {
    return null; 
  }
  
  const toggleButton = (
    <ToggleButton
      value={value}
      id={id}
      ref={ref}
      sx={{
        width: 35,
        height: 35,
        border: "1px solid",
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.background.paper,
        ...sx,
      }}
      color="primary"
      selected={selected}
      hidden = {hidden}
      onClick={handleClick}
    >
      {children}
    </ToggleButton>
  );

  return tooltip ? (
    <Tooltip title={tooltip}>
      {toggleButton}
    </Tooltip>
  ) : (
    toggleButton
  );
});

export default CustomToggleButton;
