import React from "react";
import GraphSyncing from "./GraphSyncing";
import ProductDescription from "./ProductDescription";
import { Agent, FileNode } from "../../types";
import AgentDescription from "./AgentDescription";
import TriggerProcess from "./TriggerProcess";

interface ChatPlaceHolderProps {
  selectedNode: FileNode | undefined;
  selectedAgent:
    | Agent
    | undefined
    | "debugger"
    | "optimizer"
    | "cyber_security";
  selectedChatRoom: number | null;
  syncing: boolean;
  loading: boolean;
  triggerType: string;
}
const ChatPlaceHolder: React.FC<ChatPlaceHolderProps> = ({
  selectedAgent,
  selectedNode,
  syncing,
  loading,
  triggerType,
}) => {
  const getPlaceHolder = () => {
    if (syncing) {
      return <GraphSyncing sycing={true}/>;
    }
    if (loading) {
      return <TriggerProcess triggerType={triggerType} />;
    }
    if (selectedAgent) {
      return (
        <AgentDescription
          selectedAgent={selectedAgent}
          selectedNode={selectedNode}
        />
      );
    }
    return <ProductDescription />;
  };

  return <>{getPlaceHolder()}</>;
};

export default ChatPlaceHolder;
