import React, { useState, useEffect, useContext } from "react";
import { Box, Typography } from "@mui/material";
import FileStructure from "./components/WikiFileStructure";
import { FileNode } from "./types";
import {
  getFolderContents,
  getFileContent,
  updateFile,
} from "@/services/Blar/Wiki";
import FileEditor from "./components/FileEditor";
import { Context } from "@/contexts/ContextProvider";

const WikiPage: React.FC = () => {
  const [structure, setStructure] = useState<FileNode[]>([]);
  const [selectedNode, setSelectedNode] = useState<FileNode | null>(null);
  const [fileContent, setFileContent] = useState<string>("");
  const [loadingCount, setLoadingCount] = useState<number>(0);
  const { showMessage } = useContext(Context);

  useEffect(() => {
    const fetchRootFolderContents = async () => {
      setLoadingCount((prev) => prev + 1);
      const response = await getFolderContents(null);
      const parsedStructure = parseResponse(response);
      setStructure(parsedStructure);
      setLoadingCount((prev) => prev - 1);
    };

    fetchRootFolderContents();
  }, []);

  const handleNodeSelect = async (node: FileNode | null) => {
    setSelectedNode(node);
    if (!node) {
      return;
    }
    if (node.type === "file") {
      setLoadingCount((prev) => prev + 1);
      const content = await getFileContent(node.id);
      setFileContent(content);
      setLoadingCount((prev) => prev - 1);
    } else if (node.type === "folder") {
    }
  };

  const handleFileContentChange = (content: string) => {
    setFileContent(content);
  };

  const handleSaveFile = async () => {
    if (selectedNode && selectedNode.type === "file") {
      setLoadingCount((prev) => prev + 1);
      try {
        await updateFile(selectedNode.id, {
          id: selectedNode.id,
          name: selectedNode.name,
          content: fileContent,
          folder: selectedNode.parent,
        });
        showMessage("success", "File saved successfully");
      } catch (error) {
        showMessage("error", "Failed to save file");
      } finally {
        setLoadingCount((prev) => prev - 1);
      }
    }
  };

  const parseResponse = (response: any): FileNode[] => {
    const folders = response.folders.map((folder: any) => ({
      id: folder.id,
      name: folder.name,
      type: "folder",
      path: folder.path,
      parent: folder.parent,
      children: [],
    }));

    const files = response.files.map((file: any) => ({
      id: file.id,
      name: file.name,
      type: "file",
      path: file.path,
      parent: file.folder,
      content: file.content,
    }));

    const folderMap: { [key: string]: FileNode } = {};
    folders.forEach((folder: FileNode) => {
      folderMap[`folder-${folder.id}`] = folder;
    });

    files.forEach((file: FileNode) => {
      if (file.path.includes("/")) {
        const parentPath = file.path.substring(0, file.path.lastIndexOf("/"));
        const parentFolder = folders.find(
          (folder: FileNode) => folder.path === parentPath
        );
        if (parentFolder) {
          parentFolder.children?.push(file);
        }
      }
    });

    folders.forEach((folder: FileNode) => {
      if (folder.path.includes("/")) {
        const parentPath = folder.path.substring(
          0,
          folder.path.lastIndexOf("/")
        );
        const parentFolder = folders.find(
          (parent: FileNode) => parent.path === parentPath
        );
        if (parentFolder) {
          parentFolder.children?.push(folder);
        }
      }
    });

    return [folders, files].flat();
  };

  return (
    <Box display="flex" height="100vh" overflow="hidden">
      <Box width="300px" borderRight="1px solid #ccc" p={2} overflow="auto">
        <FileStructure
          structure={structure}
          setStructure={setStructure}
          selectedNode={selectedNode}
          setSelectedNode={handleNodeSelect}
          loadingCount={loadingCount}
          setLoadingCount={setLoadingCount}
        />
      </Box>
      <Box flex={1} p={2} overflow="auto">
        {selectedNode && selectedNode.type === "file" ? (
          <FileEditor
            selectedNode={selectedNode}
            fileContent={fileContent}
            onFileContentChange={handleFileContentChange}
            onSaveFile={handleSaveFile}
          />
        ) : (
          <Typography variant="h6">
            Select a file to view its contents
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default WikiPage;
