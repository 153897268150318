import React, { useContext, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  CreateNewFolder,
  Folder,
  FolderOpen,
  InsertDriveFile,
  NoteAdd,
} from "@mui/icons-material";
import { FileNode } from "../types";
import { getFolderContents } from "@/services/Blar/Wiki";
import ContextMenu from "./ContextMenu";
import {
  createFolder,
  createFile,
  updateFolder,
  updateFile,
  deleteFolder,
  deleteFile,
} from "@/services/Blar/Wiki";

import ConfirmDeleteModal from "@/components/Tables/ConfirmDeleteModal";
import { Context } from "@/contexts/ContextProvider";

interface FileStructureProps {
  structure: FileNode[];
  setStructure: React.Dispatch<React.SetStateAction<FileNode[]>>;
  selectedNode: FileNode | null;
  setSelectedNode: (node: FileNode | null) => void;
  loadingCount: number;
  setLoadingCount: React.Dispatch<React.SetStateAction<number>>;
}

const FileStructure: React.FC<FileStructureProps> = ({
  structure,
  setStructure,
  selectedNode,
  setSelectedNode,
  loadingCount,
  setLoadingCount,
}) => {
  const [openFolders, setOpenFolders] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [editingNodeId, setEditingNodeId] = useState<number | null>(null);
  const [editingName, setEditingName] = useState<string>("");
  const [newNodeName, setNewNodeName] = useState<string>("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [creatingNode, setCreatingNode] = useState<{
    type: "file" | "folder";
    parentId: number | null;
  } | null>(null);

  const [contextMenu, setContextMenu] = useState<null | HTMLElement>(null);
  const currentNodeRef = React.useRef<FileNode | null>(null);

  const { showMessage } = useContext(Context);

  const handleClose = () => {
    setContextMenu(null);
    currentNodeRef.current = null;
  };

  const handleCreateFile = async (name: string) => {
    handleClose();
    try {
      const parentId = creatingNode?.parentId ?? null;
      const newFile = await createFile(name, parentId);
      const newFileNode: FileNode = {
        id: newFile.id,
        name: newFile.name,
        type: "file",
        parent: newFile.folder,
        path: newFile.path,
      };
      addNodeToStructure(newFileNode, parentId);
      showMessage("success", "File created successfully");
    } catch (error) {
      showMessage("error", "Failed to create file");
    }
  };

  const handleCreateFolder = async (name: string) => {
    handleClose();
    try {
      const parentId = creatingNode?.parentId ?? null;
      const newFolder = await createFolder(name, parentId);
      const newFolderNode: FileNode = {
        id: newFolder.id,
        name: newFolder.name,
        type: "folder",
        parent: newFolder.parent,
        path: newFolder.path,
        children: [],
      };
      addNodeToStructure(newFolderNode, parentId);
      showMessage("success", "Folder created successfully");
    } catch (error) {
      showMessage("error", "Failed to create folder");
    }
  };

  const handleFileClick = (node: FileNode) => {
    setSelectedNode(node);
  };

  const handleRightClick = (event: React.MouseEvent, node: FileNode) => {
    event.preventDefault();
    currentNodeRef.current = node;
    setSelectedNode(node);
    setContextMenu(event.currentTarget as HTMLElement);
  };

  const addNodeToStructure = (newNode: FileNode, parentId: number | null) => {
    if (parentId === null) {
      setStructure((prevStructure) => [...prevStructure, newNode]);
    } else {
      setStructure((prevStructure) =>
        addNodeToParent(prevStructure, newNode, parentId)
      );
    }
  };

  const handleRename = async (node: FileNode, newName: string) => {
    try {
      if (node.type === "folder") {
        await updateFolder(node.id, {
          name: newName,
          parent: node.parent,
          id: node.id,
          path: node.path,
        });
      } else {
        await updateFile(node.id, {
          name: newName,
          content: node.content || "",
          folder: node.parent,
          id: node.id,
          path: node.path,
        });
      }
      node.name = newName;
      showMessage("success", "Renamed successfully");
    } catch (error) {
      showMessage("error", "Failed to rename");
    }
  };

  const handleRenameSubmit = async () => {
    if (selectedNode && editingName) {
      await handleRename(selectedNode, editingName);
      setEditingNodeId(null);
      setEditingName("");
    }
  };

  const handleDelete = async () => {
    if (selectedNode) {
      setDeleteModalOpen(true);
    }
  };

  const confirmDelete = async () => {
    if (selectedNode) {
      try {
        if (selectedNode.type === "folder") {
          await deleteFolder(selectedNode.id);
        } else {
          await deleteFile(selectedNode.id);
        }
        setStructure((prevStructure) =>
          removeNode(prevStructure, selectedNode.id)
        );
        setSelectedNode(null);
        handleClose();
        showMessage("success", "Deleted successfully");
      } catch (error) {
        showMessage("error", "Failed to delete");
      }
    }
  };

  const handleDragStart = (event: React.DragEvent, node: FileNode) => {
    event.dataTransfer.setData("application/json", JSON.stringify(node));
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleDrop = async (event: React.DragEvent, parentNode: FileNode) => {
    event.preventDefault();
    const draggedNode = JSON.parse(
      event.dataTransfer.getData("application/json")
    ) as FileNode;
    if (draggedNode.id !== parentNode.id) {
      const updatedStructure = await moveNode(
        structure,
        draggedNode,
        parentNode
      );
      setStructure(updatedStructure);
    }
  };

  const moveNode = async (
    nodes: FileNode[],
    nodeToMove: FileNode,
    newParent: FileNode
  ): Promise<FileNode[]> => {
    try {
      const updatedNode = { ...nodeToMove, parent: newParent.id };
      if (nodeToMove.type === "file") {
        const newFile = {
          name: updatedNode.name,
          content: updatedNode.content || "",
          folder: newParent.id,
          id: updatedNode.id,
        };
        await updateFile(nodeToMove.id, newFile);
      } else {
        await updateFolder(nodeToMove.id, updatedNode);
      }
      const filteredNodes = removeNode(nodes, nodeToMove.id);
      showMessage("success", "Moved successfully");
      return addNodeToParent(filteredNodes, updatedNode, newParent.id);
    } catch (error) {
      showMessage("error", "Failed to move item");
      return nodes;
    }
  };

  const addNodeToParent = (
    nodes: FileNode[],
    nodeToAdd: FileNode | null,
    parentId: number
  ): FileNode[] => {
    if (nodeToAdd === null) {
      return nodes;
    }
    return nodes.map((node) => {
      if (node.id === parentId) {
        node.children = node.children
          ? [...node.children, nodeToAdd]
          : [nodeToAdd];
      } else if (node.children) {
        node.children = addNodeToParent(node.children, nodeToAdd, parentId);
      }
      return node;
    });
  };

  const removeNode = (nodes: FileNode[], nodeId: number): FileNode[] => {
    return nodes
      .map((node) => {
        if (node.id === nodeId) {
          return null;
        }
        if (node.children) {
          node.children = removeNode(node.children, nodeId);
        }
        return node;
      })
      .filter((node) => node !== null) as FileNode[];
  };

  const handleCreateSubmit = async () => {
    if (creatingNode && newNodeName) {
      if (creatingNode.type === "folder") {
        await handleCreateFolder(newNodeName);
      } else {
        await handleCreateFile(newNodeName);
      }
      setCreatingNode(null);
      setNewNodeName("");
    }
  };

  const handleToggle = async (node: FileNode) => {
    if (editingNodeId === node.id) {
      return;
    }

    const isOpen = openFolders[node.id];
    setOpenFolders((prev) => ({
      ...prev,
      [node.id]: !isOpen,
    }));
    if (!isOpen) {
      setSelectedNode(node);
    } else {
      setCreatingNode(null);
      setSelectedNode(null);
    }

    if (node.type === "folder" && !isOpen && !node.fetched) {
      console.log("fetching children");
      setLoadingCount((prev) => prev + 1);
      const response = await getFolderContents(node.id);
      const parsedChildren = parseResponse(response);
      node.children = parsedChildren;
      node.fetched = true;

      setStructure([...structure]);
      setLoadingCount((prev) => prev - 1);
    }
  };

  const parseResponse = (response: any): FileNode[] => {
    const folders = response.folders.map((folder: any) => ({
      id: folder.id,
      name: folder.name,
      type: "folder",
      parent: folder.parent,
      path: folder.path,
      children: [],
    }));

    const files = response.files.map((file: any) => ({
      id: file.id,
      name: file.name,
      type: "file",
      path: file.path,
      parent: file.folder,
      content: file.content,
    }));

    return [...folders, ...files];
  };

  const getRootId = (node: FileNode): number => {
    if (!node.parent) {
      return node.id;
    }
    const findNodeById = (nodes: FileNode[], id: number): FileNode | null => {
      for (const n of nodes) {
        if (n.id === id) return n;
        if (n.children) {
          const found = findNodeById(n.children, id);
          if (found) return found;
        }
      }
      return null;
    };
    const parentId = Number(node.parent);
    const parentNode = findNodeById(structure, parentId);
    if (!parentNode) {
      return parentId;
    }
    return getRootId(parentNode);
  };

  const getParentId = () => {
    if (!selectedNode) return null;
    if (selectedNode.type === "file") {
      const parentId = Number(selectedNode.parent);
      const rootId = getRootId(selectedNode);
      console.log(rootId);
      console.log(parentId);
      if (parentId === rootId) {
        return null;
      }
      return parentId;
    }
    return Number(selectedNode.id);
  };

  const renderFileTree = (
    nodes: FileNode[],
    level: number = 0
  ): React.ReactNode => {
    return nodes.map((node) => (
      <React.Fragment key={node.id}>
        <ListItemButton
          draggable
          onDragStart={(event) => handleDragStart(event, node)}
          onDragOver={handleDragOver}
          onDrop={(event) => handleDrop(event, node)}
          onContextMenu={(event) => handleRightClick(event, node)}
          onClick={node.type === "file" ? () => handleFileClick(node) : () => handleToggle(node)}
          onDoubleClick={() => {
            setEditingNodeId(node.id);
            setEditingName(node.name);
          }}
          sx={{ pl: level * 2 }}
        >
          <ListItemIcon>
            {node.type === "folder" ? (
              openFolders[node.id] ? (
                <FolderOpen />
              ) : (
                <Folder />
              )
            ) : (
              <InsertDriveFile />
            )}
          </ListItemIcon>
          {editingNodeId === node.id ? (
            <TextField
              value={editingName}
              onChange={(e) => setEditingName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleRenameSubmit();
                }
              }}
              autoFocus
            />
          ) : (
            <ListItemText primary={node.name} />
          )}
        </ListItemButton>
        {node.type === "folder" && (
          <Collapse in={openFolders[node.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderFileTree(node.children || [], level + 1)}
              {creatingNode && creatingNode.parentId === node.id && (
                <ListItemButton sx={{ pl: (level + 1) * 2 }}>
                  <TextField
                    value={newNodeName}
                    onChange={(e) => setNewNodeName(e.target.value)}
                    onBlur={handleCreateSubmit}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleCreateSubmit();
                      }
                    }}
                    autoFocus
                    placeholder={`New ${creatingNode.type}`}
                  />
                </ListItemButton>
              )}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    ));
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ cursor: "context-menu" }}
    >
      <Box display="flex" justifyContent="flex-end" p={1}>
        <Tooltip title="Create Folder">
          <IconButton
            onClick={() =>
              setCreatingNode({
                type: "folder",
                parentId: getParentId(),
              })
            }
          >
            <CreateNewFolder />
          </IconButton>
        </Tooltip>
        <Tooltip title="Create File">
          <IconButton
            onClick={() =>
              setCreatingNode({
                type: "file",
                parentId: getParentId(),
              })
            }
          >
            <NoteAdd />
          </IconButton>
        </Tooltip>
      </Box>
      {loadingCount > 0 && <LinearProgress />}
      <List>
        {renderFileTree(structure)}

        {creatingNode && creatingNode.parentId === null && (
          <ListItemButton sx={{ pl: 0 }}>
            <TextField
              value={newNodeName}
              onChange={(e) => setNewNodeName(e.target.value)}
              onBlur={handleCreateSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleCreateSubmit();
                }
              }}
              autoFocus
              placeholder={`New ${creatingNode.type}`}
            />
          </ListItemButton>
        )}
      </List>
      <ContextMenu
        anchorEl={contextMenu}
        handleClose={handleClose}
        handleCreateFile={() => {
          handleClose();
          setCreatingNode({
            type: "file",
            parentId: getParentId(),
          });
        }}
        handleCreateFolder={() => {
          handleClose();
          setCreatingNode({
            type: "folder",
            parentId: getParentId(),
          });
        }}
        handleRename={() => {
          if (selectedNode) {
            handleClose();
            setEditingNodeId(selectedNode.id);
            setEditingName(selectedNode.name);
          }
        }}
        handleDelete={handleDelete}
      />
      <ConfirmDeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        onConfirm={confirmDelete}
      />
    </Box>
  );
};

export default FileStructure;
