import React from "react";
import { CircularProgress, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface GrapsSyncingProps {
  sycing: boolean;
};

const GraphSyncing: React.FC<GrapsSyncingProps> = ({ sycing }) => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        borderColor: theme.palette.divider,
        maxWidth: 320,
        padding: 3,
      }}
    >
      <CircularProgress size={65} />
      {sycing && (
        <Typography variant="body1" textAlign={"start"} mt={2}>
        Blar is building your company's graph. Depending on the size of your
        codebase, this process might take up to 10 minutes
        </Typography>
      )}
    </Paper>
  );
};

export default GraphSyncing;
