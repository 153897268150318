import React, { useState, useEffect, useContext } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNightBright as codeStyle } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { FileNode } from "../types";
import rehypeRaw from "rehype-raw";
import { updateFile } from "@/services/Blar/Wiki";
import { Context } from "@/contexts/ContextProvider";

interface FileEditorProps {
  selectedNode: FileNode;
  fileContent: string;
  onFileContentChange: (content: string) => void;
  onSaveFile: () => void;
}

const FileEditor: React.FC<FileEditorProps> = ({
  selectedNode,
  fileContent,
  onFileContentChange,
  onSaveFile,
}) => {
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(selectedNode.name);
  const [showEditor, setShowEditor] = useState<boolean>(true);
  const { showMessage } = useContext(Context);

  useEffect(() => {
    setTitle(selectedNode.name);
  }, [selectedNode]);

  const handleTitleDoubleClick = () => {
    setIsEditingTitle(true);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleTitleBlur = () => {
    setIsEditingTitle(false);
    onSaveFile();
  };

  const handleRename = async (node: FileNode, newName: string) => {
    try {
      await updateFile(node.id, {
        name: newName,
        content: node.content || "",
        folder: node.parent,
        id: node.id,
        path: node.path,
      });
      node.name = newName;
      showMessage("success", "File renamed successfully");
    } catch (error) {
      showMessage("error", "Failed to rename file");
    }
  };

  const handleRenameSubmit = async () => {
    if (selectedNode && title) {
      await handleRename(selectedNode, title);
      setIsEditingTitle(false);
    }
  };

  const handleEditorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFileContentChange(event.target.value);
  };

  const toggleEditorPreview = () => {
    setShowEditor((prev) => !prev); 
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box display="flex" justifyContent="space-between" mx={2}>
        {isEditingTitle ? (
          <TextField
            value={title}
          onChange={handleTitleChange}
          onBlur={handleTitleBlur}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleRenameSubmit();
            }
          }}
          autoFocus
        />
      ) : (
        <Typography variant="h6"onDoubleClick={handleTitleDoubleClick}>
          {title}
        </Typography>
      )}
          <Button variant="contained" onClick={toggleEditorPreview}>
            {showEditor ? "Show Preview" : "Show Editor"}
          </Button>
      </Box>

      <Box display="flex" flex={1} overflow="hidden">
        {showEditor ? (
          <Box display="flex" flex={1} p={2}>
            <TextField
              value={fileContent}
              onChange={handleEditorChange}
              multiline
              variant="outlined"
              fullWidth
              sx={{
                '& .MuiInputBase-root': {
                  height: '100%',
                },
                '& .MuiInputBase-input': {
                  height: '100% !important',
                  overflow: 'auto !important',
                },
              }}
            />
          </Box>
        ) : (
          <Box flex={1} p={2} overflow="auto">
            <Typography variant="h6" align="left">Preview</Typography>
            <Box textAlign={"start"} p={2}>
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={fileContent}
                components={{
                  code({ node, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return match ? (
                      <div style={{ position: "relative" }}>
                        <SyntaxHighlighter
                          children={String(children).replace(/\n$/, "")}
                          language={match[1]}
                          style={codeStyle}
                          customStyle={{
                            borderRadius: "20px",
                            padding: "20px",
                            fontSize: "14px",
                          }}
                        />
                      </div>
                    ) : (
                      <code
                        {...props}
                        className={className}
                        style={{
                          textOverflow: "ellipsis",
                          wordBreak: "break-word",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {children}
                      </code>
                    );
                  },
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
      {showEditor ? <Box display="flex" mr={1} justifyContent="flex-end">
              <Button variant="contained" onClick={onSaveFile} color="blarSuccess">
                Save
              </Button>
            </Box> : null}
    </Box>
  );
};

export default FileEditor;
