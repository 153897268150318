import React, { useContext } from "react";
import { Box, Typography, TextField, Button, IconButton } from "@mui/material";
import { submit_feedback } from "@/services/Blar/Chat";

import CloseIcon from "@mui/icons-material/Close";
import { Context } from "@/contexts/ContextProvider";
interface FeedbackBoxProps {
  setFeedbackOpen: (open: boolean) => void;
  feedbackMessageId: number | null;
}

const FeedbackBox: React.FC<FeedbackBoxProps> = ({
  setFeedbackOpen,
  feedbackMessageId,
}) => {
  const [feedbackText, setFeedbackText] = React.useState("");
  const { showMessage } = useContext(Context);

  const submitFeedback = async () => {
    if (feedbackMessageId !== null) {
      try {
        if (feedbackText) {
          await submit_feedback(feedbackMessageId, feedbackText);
          showMessage("success", "Thanks for the feedback!");
        }
      } catch (error) {
        showMessage("error", "Error sending feedback");
      } finally {
        setFeedbackOpen(false);
        setFeedbackText("");
      }
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        padding: 2,
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
        backgroundColor: "background.paper",
        marginTop: 2,
        width: "75%",
      }}
    >
      <IconButton
        size="small"
        onClick={() => setFeedbackOpen(false)}
        sx={{ position: "absolute", top: 8, right: 8 }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <Typography variant="body1">Provide additional feedback</Typography>
      <TextField
        value={feedbackText}
        onChange={(e) => setFeedbackText(e.target.value)}
        multiline
        variant="outlined"
        fullWidth
        sx={{ marginTop: 1 }}
        size="small"
        placeholder="Feel free to share your feedback"
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 1 }}>
        <Button
          onClick={submitFeedback}
          color="blarSuccess"
          variant="contained"
          size="small"
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default FeedbackBox;
