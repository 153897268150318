import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
} from "@mui/material";
import { get_video_demo } from "@/services/Blar/Agents";
import { Frame, VideoDemo } from "../../../../interfaces/IGraphVideo";
import { Context } from "@/contexts/ContextProvider";

import { useReactFlow, useNodesInitialized } from "reactflow";
import ReactMarkdown from "react-markdown";

type GraphVideoStepPanelProps = {
  selectedGraphVideo: number | null;
  setHightLightNode: (nodeId: string | undefined) => void;
  FetchNodeDetail: (nodeId: string) => void;
  setHighlightedPath: (path: string[]) => void;
  height?: string;
};

const options = {
  includeHiddenNodes: true,
};

const GraphVideoStepPanel: React.FC<GraphVideoStepPanelProps> = ({
  selectedGraphVideo,
  setHightLightNode,
  FetchNodeDetail,
  height,
  setHighlightedPath,
}) => {
  const { showMessage } = useContext(Context);
  const [frames, setFrames] = useState<Frame[]>([]);
  const [videoDemo, setVideoDemo] = useState<VideoDemo | null>(null);
  const [step, setStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const reactFlow = useReactFlow();
  const nodesInitialized = useNodesInitialized(options);

  const changeViewport = (nodeId: string) => {
    if (!reactFlow) return;
    const node = reactFlow.getNode(nodeId);
    if (!node) return;

    reactFlow.fitView({
      nodes: [node],
      duration: 8000,
      padding: 12,
      includeHiddenNodes: true,
    });
  };

  const handleNextStep = () => {
    if (step < frames.length - 1) {
      setStep(step + 1);
    } else {
      setStep(0);
    }
  };

  const handlePreviousStep = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      setStep(frames.length - 1);
    }
  };

  const formatTime = (createdAt: string | undefined) => {
    if (!createdAt) return "";
    const date = new Date(createdAt);
    return date.toLocaleTimeString();
  };

  useEffect(() => {
    if (selectedGraphVideo && frames.length > 0) {
      setHightLightNode(frames[step].node_id);
      FetchNodeDetail(frames[step].node_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGraphVideo, frames, step]);

  useEffect(() => {
    if (selectedGraphVideo && frames.length > 0 && nodesInitialized) {
      const timer = setTimeout(() => {
        changeViewport(frames[step].node_id);
      }, 20);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGraphVideo, frames, step, nodesInitialized]);

  useEffect(() => {
    async function fetchAgentSolutions() {
      try {
        setLoading(true);
        const videoDemo = (await get_video_demo(selectedGraphVideo!)).data[0];
        const sortedFrames = videoDemo.frames.sort(
          (a: Frame, b: Frame) => a.position - b.position
        );
        setVideoDemo(videoDemo);

        setFrames(sortedFrames);
        setHighlightedPath(sortedFrames.map((frame: Frame) => frame.node_id));
        setHightLightNode(sortedFrames[step].node_id);
        FetchNodeDetail(sortedFrames[step].node_id);
      } catch (error) {
        showMessage("error", `Failed to fetch frames`);
      } finally {
        setLoading(false);
      }
    }
    setStep(0);
    if (selectedGraphVideo) fetchAgentSolutions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGraphVideo]);
  return (
    <Paper
      sx={{
        width: "100%",
        padding: "20px !important",
        boxSizing: "border-box",
        height: height ? height : "70vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "baseline",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h5" style={{ marginRight: "10px" }}>
          Chain of thoughts
        </Typography>
        <Typography variant="body1">
          {formatTime(videoDemo?.created_at)}
        </Typography>
      </Box>

      <Box sx={{ flexGrow: 1, textAlign: "left", overflowY: "auto" }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%", // Ensures the Box takes up the full height of its container
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <ReactMarkdown>
            {frames[step]?.thoughts
              ? frames[step].thoughts.replace("## Thoughts:", "")
              : ""}
          </ReactMarkdown>
        )}
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between", // Distribute items evenly
          alignItems: "baseline",
          marginTop: "20px",
        }}
      >
        <Button
          variant="outlined"
          color="blarBlack"
          onClick={handlePreviousStep}
        >
          prev step
        </Button>
        <Typography
          variant="h6"
          style={{
            marginRight: "10px",
          }}
        >
          step {step + 1} of {loading ? "🤔" : frames.length}
        </Typography>
        <Button variant="outlined" color="blarBlack" onClick={handleNextStep}>
          next step
        </Button>
      </Box>
    </Paper>
  );
};

export default GraphVideoStepPanel;
