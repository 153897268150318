import * as Yup from "yup";

import {
  Alert,
  Box,
  CardContent,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { serverSignIn, serverSignUp } from "../../services/Blar/Auth";
import { useLocation, useNavigate } from "react-router-dom";

import { AxiosError } from "axios";
import { Context } from "@/contexts/ContextProvider";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import useStyles from "./styles/AuthForm";

interface AuthFormValues {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  password: string;
  authError?: string;
}

interface AuthFormProps {
  continueOnboarding?: () => void;
}

const AuthForm: React.FC<AuthFormProps> = ({
  continueOnboarding,
}: AuthFormProps) => {
  const classes = useStyles();
  const { signIn } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const formik = useFormik<AuthFormValues>({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().when("email", {
        is: () => currentPath.includes("/onboarding"),
        then: (schema) => schema.required("Name is required"),
        otherwise: (schema) => schema,
      }),
      lastName: Yup.string().when("email", {
        is: () => currentPath.includes("/onboarding"),
        then: (schema) => schema.required("Last name is required"),
        otherwise: (schema) => schema,
      }),
      companyName: Yup.string().when("email", {
        is: () => currentPath.includes("/onboarding"),
        then: (schema) => schema.required("Company name is required"),
        otherwise: (schema) => schema,
      }),
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/\d/, "Password must contain at least one number")
        .required("Required"),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        let authResponse;
        if (currentPath.includes("/onboarding")) {
          await serverSignUp({
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            password: values.password,
            company_name: values.companyName,
          });
          authResponse = await serverSignIn(values.email, values.password);
          await signIn(authResponse.data.access, authResponse.data.refresh);
          if (continueOnboarding) continueOnboarding();
          return;
        }
        authResponse = await serverSignIn(values.email, values.password);
        await signIn(authResponse.data.access, authResponse.data.refresh);
        const redirectPath = new URLSearchParams(location.search).get(
          "redirect"
        );
        navigate(redirectPath || "/chat");
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 400) {
          if (axiosError.response.data) {
            const errorData = axiosError.response.data as {
              [key: string]: string[];
            };
            const errors: string[] = [];
            for (const key of Object.keys(errorData)) {
              if (errorData.hasOwnProperty(key)) {
                errors.push(`${key}: ${errorData[key]}`);
              }
            }
            const errorString: string = errors.reduce(
              (acc, curr) => acc + curr + "\n",
              ""
            );
            setErrors({ authError: errorString });
          }
        } else {
          setErrors({
            authError:
              "The username or password you entered is incorrect. Please try again.",
          });
        }
      }
      setSubmitting(false);
    },
  });

  const displayLogo = () => {
    return currentPath.includes("/onboarding") ? false : true;
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
      width={"100%"}
    >
      <Box display={"block"} mt={1}>
        <Typography
          variant="h3"
          align="center"
          fontSize={50}
          sx={{
            lineHeight: "40px",
            marginBottom: "20px",
          }}
        >
          Welcome!
        </Typography>
        {displayLogo() && (
          <Box component={"img"} src="/logos/BlueLogo.svg" alt="Blar" />
        )}
        <Box mt={1}>
          <CardContent>
            <Box
              sx={{ width: 300 }}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              component="form"
              onSubmit={formik.handleSubmit}
              noValidate
            >
              <Grid
                container
                rowSpacing={2}
                direction="column"
                className={classes.form}
              >
                {currentPath.includes("/onboarding") && (
                  <>
                    <Grid item sx={{ width: "80%" }}>
                      <TextField
                        fullWidth
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                          },
                        }}
                        placeholder="Name"
                        type="text"
                        id="firstName"
                        {...formik.getFieldProps("firstName")}
                        error={
                          formik.touched.firstName &&
                          Boolean(formik.errors.firstName)
                        }
                        helperText={
                          formik.touched.firstName && formik.errors.firstName
                        }
                      />
                    </Grid>
                    <Grid item sx={{ width: "80%" }}>
                      <TextField
                        fullWidth
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                          },
                        }}
                        placeholder="Last Name"
                        type="text"
                        id="lastName"
                        {...formik.getFieldProps("lastName")}
                        error={
                          formik.touched.lastName &&
                          Boolean(formik.errors.lastName)
                        }
                        helperText={
                          formik.touched.lastName && formik.errors.lastName
                        }
                      />
                    </Grid>
                    <Grid item sx={{ width: "80%" }}>
                      <TextField
                        fullWidth
                        InputProps={{
                          sx: {
                            borderRadius: "10px",
                          },
                        }}
                        placeholder="Company Name"
                        type="text"
                        id="companyName"
                        {...formik.getFieldProps("companyName")}
                        error={
                          formik.touched.companyName &&
                          Boolean(formik.errors.companyName)
                        }
                        helperText={
                          formik.touched.companyName &&
                          formik.errors.companyName
                        }
                      />
                    </Grid>
                  </>
                )}
                <Grid item sx={{ width: "80%" }}>
                  <TextField
                    fullWidth
                    InputProps={{
                      sx: {
                        borderRadius: "10px",
                      },
                    }}
                    placeholder="Email"
                    type="text"
                    id="email"
                    {...formik.getFieldProps("email")}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.errors.email}
                  />
                </Grid>
                <Grid item sx={{ width: "80%" }}>
                  <TextField
                    fullWidth
                    InputProps={{
                      sx: {
                        borderRadius: "10px",
                      },
                    }}
                    placeholder="Password"
                    type="password"
                    id="password"
                    {...formik.getFieldProps("password")}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </Grid>
                <Grid item>
                  {formik.errors.authError && (
                    <Alert severity="error">{formik.errors.authError}</Alert>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  className={classes.actions}
                >
                  <LoadingButton
                    loading={formik.isSubmitting}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="large"
                    style={{ fontSize: "20px" }}
                    className={classes.authButton}
                  >
                    {currentPath.includes("/onboarding") ? "Sign Up" : "Login"}
                  </LoadingButton>
                  {currentPath === "/login" ? (
                    <Link
                      href="/onboarding"
                      underline="hover"
                      color="secondary"
                    >
                      Don't have an account? Sign Up
                    </Link>
                  ) : (
                    <Link href="/login" underline="hover" color="secondary">
                      Already have an account? Login
                    </Link>
                  )}
                  <br />
                  {/* <Link href="/login/playground" underline="hover" color="secondary">
                    Want to try  a playground? Click here
                  </Link> */}
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Box>
      </Box>
    </Box>
  );
};

export default AuthForm;
