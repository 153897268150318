import React, { useContext, useEffect, useState } from "react";
import { Button, Typography, Box, Link } from "@mui/material";
import useStyles from "./styles/Integrations";
import ContinueButton from "@/components/Buttons/ContinueButton";
import {
  check_integration_exists,
  slack_oauth,
} from "@/services/Blar/Integrations";
import { IntegrationType } from "@/interfaces/IIntegration";
import { Context } from "@/contexts/ContextProvider";
import CheckIcon from "@mui/icons-material/Check";
import SentryModal from "@/components/Integrations/SentryModal";
import { integrations } from "@/components/Integrations/integrations";

interface NotificationsIntegrationProps {
  continueOnboarding: () => void;
}

const NotificationsIntegrationComponent: React.FC<
  NotificationsIntegrationProps
> = ({ continueOnboarding }) => {
  const classes = useStyles();
  const { showMessage } = useContext(Context);
  const [isSentryConnected, setIsSentryConnected] = useState(false);

  const checkSentryConnection = () => {
    try {
      check_integration_exists({ source: IntegrationType.SENTRY }).then(
        (res) => {
          const is_connected = res.data;
          setIsSentryConnected(is_connected);
        }
      );
    } catch (error) {
      showMessage("error", "Failed to get Slack integration");
    }
  };

  useEffect(() => {
    // Function to check Sentry connection
    checkSentryConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.root}>
      <Typography
        variant="h3"
        align="center"
        fontSize={40}
        sx={{
          lineHeight: "40px",
        }}
      >
        3. Connect to Sentry (optional)
        {isSentryConnected && <CheckIcon fontSize={"large"} color="success" />}
      </Typography>
      {!isSentryConnected && (
        <>
          <br/>
          <Box className={classes.loggerToolBox}>
            <Typography fontSize={20} variant="body2" align="left">
              To connect to Sentry, you will need to follow the steps listed on
              the{" "}
              <Link
                href="https://blar.io/docs"
                target="_blank"
                rel="noopener noreferrer"
              >
                documentation
              </Link>
            </Typography>
            <br />
            <Typography fontSize={20} variant="body2" align="left">
              If you encounter any problems with Sentry, or have another
              monitoring tool, please schedule a call with our customer success
              team{" "}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://calendly.com/patdaher"
              >
                here
              </Link>
              !
            </Typography>
            <br />
          </Box>
        </>
      )}
      <ContinueButton continueOnboarding={continueOnboarding} />
    </Box>
  );
};

export default NotificationsIntegrationComponent;
