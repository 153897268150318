import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import SearchIcon from "@mui/icons-material/Search";

import { debounce } from "@mui/material/utils";
import { CircularProgress, Tooltip } from "@mui/material";
import DataObjectOutlinedIcon from "@mui/icons-material/DataObjectOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { FileNode } from "@/pages/Chat/components/types";
import InfoIcon from "@mui/icons-material/Info";

const SEARCH_TOOLTIP =
  "You can search parts of your code (functions, classes, files) by replacing / in the path with a .. For example, src/components/Header.js becomes src.components.Header. You can also search specific parts, like Header alone";

interface MenuSearchProps {
  onChange: (value: string) => void;
  placeholder?: string;
  loading?: boolean;
  options: FileNode[];
  setSelectedNode: (node: FileNode) => void;
  value: FileNode | null;
  selectedRepo?: string;
  selectedNode: FileNode | null;
}

const MenuSearch: React.FC<MenuSearchProps> = ({
  onChange,
  options,
  placeholder,
  loading = false,
  setSelectedNode,
  value,
  selectedRepo,
  selectedNode,
}) => {
  const [inputValue, setInputValue] = React.useState("");

  const fetch = React.useMemo(
    () =>
      debounce((input: string | null) => {
        onChange(input || "");
      }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRepo]
  );

  React.useEffect(() => {
    fetch(inputValue);
  }, [inputValue, fetch, selectedNode]);

  const formatPath = (path: string) => {
    // Split by both "/" and "."
    const segments = path.split(/[/. ]/);
    // Remove the first two segments
    const remainingSegments = segments.slice(3);
    // Join the remaining segments with dots
    return remainingSegments.join(".");
  };

  const getIcon = (type: string[]) => {
    if (type.includes("CLASS")) {
      return <DataObjectOutlinedIcon sx={{ color: "#FFFFFF" }} />;
    } else if (type.includes("FILE")) {
      return <InsertDriveFileOutlinedIcon sx={{ color: "#FFFFFF" }} />;
    } else if (type.includes("FUNCTION")) {
      return (
        <img
          src="/icons/function-icon-white.png"
          alt="Function Icon"
          style={{ color: "#FFFFFF", width: "22px", height: "22px" }}
        />
      );
    } else {
      return <InsertDriveFileOutlinedIcon sx={{ color: "#FFFFFF" }} />;
    }
  };

  return (
    <Box display={"flex"} justifyContent={"space-between"}>
      <Autocomplete
        getOptionLabel={(option) =>
          typeof option === "string" ? option : formatPath(option.path)
        }
        sx={{
          "& fieldset": { borderRadius: "10px" },
          "& .MuiAutocomplete-popupIndicator": { transform: "none" },
          flexGrow: 1,
        }}
        size="small"
        isOptionEqualToValue={(option, value) =>
          value ? option.node_id === value.node_id : false
        }
        loading={loading}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        value={value}
        noOptionsText={"No results found"}
        popupIcon={<SearchIcon />}
        onChange={(event: any, newValue: any | null) => {
          setSelectedNode(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <Tooltip title={formatPath(selectedNode?.path || "")} placement="top-start">
            <TextField
              {...params}
              label={placeholder}
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              color="primary"
              size="small"
            />
          </Tooltip>
        )}
        renderOption={(props, option) => {
          // @ts-ignore
          const { key, ...optionProps } = props;
          const matches = match(formatPath(option.path), inputValue);
          const parts = parse(formatPath(option.path), matches);
          return (
            <li key={option.node_id} {...optionProps}>
              <Tooltip
                title={formatPath(option.path)}
                placement="top-start"
                enterNextDelay={800}
              >
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid item sx={{ marginRight: "10px" }}>
                    {getIcon(option.type)}
                  </Grid>
                  <Grid
                    item
                    sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                  >
                    {parts.map((part, index) => (
                      <Box
                        key={index}
                        component="span"
                        sx={{ fontWeight: part.highlight ? 1000 : "regular" }}
                      >
                        {part.text}
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </Tooltip>
            </li>
          );
        }}
      />
      <Box
        display={"flex"}
        height={"100%"}
        justifyContent={"center"}
        padding={1}
        alignItems={"center"}
      >
        <Tooltip title={SEARCH_TOOLTIP}>
          <InfoIcon fontSize="small" color="primary" />
        </Tooltip>
      </Box>
    </Box>
  );
};

export default MenuSearch;
