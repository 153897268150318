import React, { useState } from "react";
import {
  Box,
  Card,
  Chip,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { mapStatusesColors, mapCriticalitiesColors } from "./helpers";
import { Circle } from "@mui/icons-material";
import { Priorities } from "../types";
import Timestamp from "@/components/Typographies/Timestamp";
import AssignUserMenu from "./AssignUserMenu";
import { code_error_put } from "@/services/Blar/Integrations";
import { Context } from "@/contexts/ContextProvider";
import { ChatsRoomResponse } from "@/interfaces/IChatRoom";
import UserAvatars from "./UserAvatars";
import { mapMessageUserToUser } from "../helpers";
import { useTheme } from "@mui/material/styles";
import ConfirmDeleteModal from "@/components/Tables/ConfirmDeleteModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { delete_chat_room } from "@/services/Blar/Chat";
interface CardProps {
  selected: boolean;
  data: ChatsRoomResponse;
  setRefreshChatRoomsTimestamp: (timestamp: number) => void;
  onClick?: () => void;
}

const MessageCard: React.FC<CardProps> = ({
  selected,
  data,
  setRefreshChatRoomsTimestamp,
  onClick,
}) => {
  const theme = useTheme();

  const [loading, setLoading] = React.useState(false);
  const { showMessage } = React.useContext(Context);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    has_unread_messages: hasUnreadMessages,
    trigger_type: type,
    trigger_id: triggerId,
    trigger: {
      source,
      priority: criticality,
      state: status,
      assigned_to: assignedTo,
    },
    users,
    name: bodyText,
    last_message_time: timestamp,
  } = data;
  const assignUser = async (userId: number) => {
    try {
      setLoading(true);
      await code_error_put({ id: triggerId, assigned_to: userId });
      setRefreshChatRoomsTimestamp(Date.now());
    } catch (error) {
      showMessage("error", "Failed to assign user");
    } finally {
      setLoading(false);
    }
  };

  const getCardIcon = (type: string, source?: string): React.ReactNode => {
    switch (type) {
      case "codeerror":
        switch (source) {
          case "sentry":
            return (
              <img
                src="/integrations/SentryIcon.svg"
                alt="Sentry Logo"
                style={{ height: 12 }}
              />
            );
          default:
            return null;
        }
      case "baseuser":
        return (
          <AccountCircleOutlinedIcon
            sx={{ fontSize: 15, marginRight: 0, marginLeft: 0 }}
          />
        );
      default:
        return "info";
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // Prevents event propagation to Card
    setAnchorEl(null);
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    setIsModalOpen(false);
    try {
      setLoading(true);
      await delete_chat_room(data.id);
      setRefreshChatRoomsTimestamp(Date.now());
    } catch (error) {
      showMessage("error", "Failed to delete chatroom");
    } finally {
      setLoading(false);
    }
  };

  const getCriticallityBadgeIcon = (
    criticality: Priorities | null | undefined
  ): React.ReactNode => {
    if (criticality) {
      return (
        <Chip
          sx={{ marginRight: "5px" }}
          label={criticality}
          color={mapCriticalitiesColors(criticality)}
          variant="outlined"
          size="small"
        />
      );
    }
    return <></>;
  };

  return (
    <>
      <Card
        onClick={onClick}
        sx={{
          cursor: onClick ? "pointer" : "default",
          background: selected
            ? "#30363D"
            : hasUnreadMessages
            ? "#2B456B"
            : "#161B22",
          border: "1px solid",
          borderColor: hasUnreadMessages
            ? "rgba(11, 100, 255, 0.5)"
            : theme.palette.divider,
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              {getCardIcon(data.trigger_type, data.trigger.source)}
              {data.trigger.state && (
                <Circle
                  color={mapStatusesColors(data.trigger.state)}
                  sx={{
                    height: "12px",
                    textAlign: "start",
                    fontSize: "inherit",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                />
              )}
              {getCriticallityBadgeIcon(data.trigger.priority)}
            </Box>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleMenuOpen}
              sx={{
                marginLeft: "5px", // Adding space between elements
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
                textAlign: "end",
              }}
              size="small"
            >
              <MoreVertIcon sx={{ fontSize: 20 }} />
            </IconButton>
          </Box>
          <Box mt={1}>
            <Typography
              variant="body1"
              color="text.secondary"
              textAlign="start"
              fontSize={15}
              sx={{
                padding: "0px",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {bodyText}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
          >
            {source === "sentry" ? (
              <>
                {loading ? (
                  <CircularProgress size={5} />
                ) : (
                  <AssignUserMenu
                    onAssign={assignUser}
                    assignedTo={assignedTo}
                  />
                )}
              </>
            ) : (
              <UserAvatars users={users.map(mapMessageUserToUser) || []} />
            )}
            <Box flexGrow={1} />
            <Timestamp timestamp={timestamp} />
          </Box>
        </Box>
      </Card>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{ padding: "0px" }}
      >
        <MenuItem
          onClick={handleDeleteClick}
          sx={{
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
            color: "black",
          }}
        >
          <ListItemIcon sx={{ color: "blarCritical.main" }}>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: "blarCritical.main" }}>
            Delete
          </ListItemText>
        </MenuItem>
      </Menu>

      <ConfirmDeleteModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default MessageCard;
