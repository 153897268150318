import { Typography, Grid, IconButton } from "@mui/material";
import { TableRowData } from "@/components/Tables/types";

import TableWithActions from "@/components/Tables/TableWithActions";
import AddIcon from "@mui/icons-material/Add";
import { useContext, useEffect, useState } from "react";
import { list_users } from "@/services/Blar/Company";
import { convertUsersToRow } from "./helper";
import { Context } from "@/contexts/ContextProvider";
import AddUsersModal from "./components/AddUsersModal";
import { User } from "@/interfaces/ICompany";
import PaymentModal from "@/components/Payment/PaymentModal";

const defaultUsersTitles = ["Email", "Joined Date", "Active"];

const UsersTable = () => {
  const [openAddUser, setOpenAddUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userRows, setUserRows] = useState<TableRowData[]>([]);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  const { showMessage, companyTier } = useContext(Context);

  useEffect(() => {
    async function fetchUsersData() {
      try {
        setLoading(true);
        // TODO: Handle pagination
        const usersResult = (await list_users({ limit: 50, offset: 0 })).data
          .results as User[];
        const usersRows = convertUsersToRow(usersResult);
        setUserRows(usersRows);
      } catch (error) {
        showMessage("error", `Failed to fetch users`);
      } finally {
        setLoading(false);
      }
    }

    fetchUsersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddUserClick = () => {
    if (companyTier != null && companyTier >= 2) {
      setOpenAddUser(true);
    } else {
      setOpenPaymentModal(true);
    }
  };

  return (
    <>
      <Grid container alignItems="center" sx={{ marginBottom: "20px" }}>
        <Grid item>
          <Typography variant="h6">Users</Typography>
        </Grid>
        <Grid item>
          <IconButton
            sx={{
              border: "1px solid #FFFFFF4D",
              color: "gray",
              marginLeft: "10px",
            }}
            size="small"
            onClick={handleAddUserClick}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
      <TableWithActions
        loading={loading}
        tableRows={userRows}
        defaultTitles={defaultUsersTitles}
      />
      <AddUsersModal
        open={openAddUser}
        setOpen={setOpenAddUser}
        showMessage={showMessage}
      />
      <PaymentModal
        open={openPaymentModal}
        onClose={() => setOpenPaymentModal(false)}
      />
    </>
  );
};

export default UsersTable;
