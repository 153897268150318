import React, { useState, MouseEvent, useContext } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";
import { list_users } from "@/services/Blar/Company";
import { Context } from "@/contexts/ContextProvider";
import { AssignedTo } from "@/interfaces/IChatRoom";
import UserAvatar from "@/components/User/UserAvatar";
import { mapAssignedToToUser } from "../helpers";
import { User } from "@/interfaces/ICompany";

interface AssignMenuProps {
  onAssign: (assignee: number) => void;
  assignedTo?: AssignedTo;
}

const AssignUserMenu: React.FC<AssignMenuProps> = ({
  onAssign,
  assignedTo,
}) => {
  const { showMessage } = useContext(Context);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const handleClick = async (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);

    // Fetch users when the menu is opened
    if (users.length === 0) {
      try {
        setLoading(true);
        const usersResult = (await list_users({ limit: 10, offset: 0 })).data
          .results as User[];
        setUsers(usersResult);
      } catch (error) {
        showMessage("error", "Failed to fetch users");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAssign = (assignee: number) => {
    onAssign(assignee);
    handleClose();
  };

  return (
    <>
      {assignedTo?.id ? (
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ padding: 0, margin: 0, minWidth: 0 }}
        >
          <UserAvatar user={mapAssignedToToUser(assignedTo)} />
        </IconButton>
      ) : (
        <Button
          onClick={handleClick}
          variant="text"
          color="inherit"
          size="small"
          sx={{ textTransform: "none", padding: 0, margin: 0, minWidth: 0 }}
        >
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ textDecoration: "underline" }}
          >
            Assign
          </Typography>
        </Button>
      )}

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={2}
            minWidth={200}
          >
            <CircularProgress size={24} />
          </Box>
        ) : (
          users.map((user) => (
            <MenuItem
              key={user.user_id}
              onClick={() => handleAssign(user.id)}
              selected={user.id === assignedTo?.id}
            >
              {user.email}
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  );
};

export default AssignUserMenu;
