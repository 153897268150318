import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Alert,
  Paper,
  Button,
} from "@mui/material";
import useStyles from "./styles/Integrations";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Context } from "@/contexts/ContextProvider";
import {
  get_graph_db_config,
  post_graph_db_config,
} from "@/services/Blar/GraphDBConfig";
import { AxiosError } from "axios";
import { IGraphDBConfiguration } from "@/interfaces/IGraphConfig";
import LoadingButton from "@mui/lab/LoadingButton";

interface GraphDBConfigurationValues {
  data: IGraphDBConfiguration;
  authError?: string;
}
const GraphDBConfigurationComponent: React.FC = () => {
  const classes = useStyles();
  const { showMessage } = useContext(Context);
  const [useOwnDB, setUseOwnDB] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    const fecthGraphDBConfig = async () => {
      try {
        const response = await get_graph_db_config();
        const config = response.data;
        formik.setValues({
          data: {
            use_own_db: config.use_own_db,
            uri: config.uri,
            user: config.user,
            password: config.password,
          },
        });
        setUseOwnDB(response.data.use_own_db);
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === 404) {
            setUseOwnDB(false);
            return;
          }
        }
        showMessage("error", "Failed to fetch GraphDB configuration");
      }
    };

    fecthGraphDBConfig();
  }, []);

  const handleEdit = () => {
    setEdit(true);
    formik.setValues({
      data: {
        use_own_db: useOwnDB,
        uri: "",
        user: "",
        password: "",
      },
    });
  };

  const formik = useFormik<GraphDBConfigurationValues>({
    validateOnChange: true,
    initialValues: {
      data: {
        use_own_db: true,
        uri: "",
        user: "",
        password: "",
      },
    },
    isInitialValid: false,
    validationSchema: Yup.object({
      data: Yup.object({
        use_own_db: Yup.boolean().required(),
        uri: Yup.string().when("use_own_db", {
          is: true,
          then: (schema) =>
            Yup.string().required("URI is required when using your own DB"),
          otherwise: (schema) => Yup.string().notRequired(),
        }),
        user: Yup.string().when("use_own_db", {
          is: true,
          then: (schema) =>
            Yup.string().required("User is required when using your own DB"),
          otherwise: (schema) => Yup.string().notRequired(),
        }),
        password: Yup.string().when("use_own_db", {
          is: true,
          then: (schema) =>
            Yup.string().required(
              "Password is required when using your own DB"
            ),
          otherwise: (schema) => Yup.string().notRequired(),
        }),
      }),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        await post_graph_db_config(values.data);
        setEdit(false);
        showMessage("success", "GraphDB configuration saved successfully");
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 400) {
          if (axiosError.response.data) {
            const errorData = axiosError.response.data as {
              detail: { [key: string]: string[] };
            };
            const errorDataDetails = errorData.detail;
            const errors: string[] = [];
            for (const key of Object.keys(errorDataDetails)) {
              if (errorDataDetails.hasOwnProperty(key)) {
                errors.push(`${key}: ${errorDataDetails[key]}`);
              }
            }
            const errorString: string = errors.reduce(
              (acc, curr) => acc + curr + "\n",
              ""
            );
            setErrors({ authError: errorString });
          }
        }
      }
      setSubmitting(false);
    },
  });

  return (
    <Box className={classes.root}>
      {useOwnDB && (
        <>
          <Typography variant="h6">Graph Database</Typography>
          <br />
          <Paper className={classes.form}>
            <Grid container spacing={2}>
              <Grid className={classes.inputText} item>
                <Typography variant="h6" fontSize={20} align="left">
                  Uri
                </Typography>
                <TextField
                  fullWidth
                  InputProps={{
                    readOnly: !edit,
                    sx: {
                      borderRadius: "10px",
                      height: "40px",
                    },
                  }}
                  placeholder="URI"
                  type="text"
                  id="data.uri"
                  {...formik.getFieldProps("data.uri")}
                  error={
                    formik.touched.data?.uri && Boolean(formik.errors.data?.uri)
                  }
                  helperText={formik.errors.data?.uri}
                />
              </Grid>
              <Grid className={classes.inputText} item>
                <Typography variant="h6" fontSize={20} align="left">
                  User
                </Typography>
                <TextField
                  fullWidth
                  InputProps={{
                    readOnly: !edit,

                    sx: {
                      borderRadius: "10px",
                      height: "40px",
                    },
                  }}
                  placeholder="USER"
                  type="text"
                  id="data.user"
                  {...formik.getFieldProps("data.user")}
                  error={
                    formik.touched.data?.user &&
                    Boolean(formik.errors.data?.user)
                  }
                  helperText={formik.errors.data?.user}
                />
              </Grid>
              <Grid className={classes.inputText} item>
                <Typography variant="h6" fontSize={20} align="left">
                  Password
                </Typography>
                <TextField
                  fullWidth
                  InputProps={{
                    readOnly: !edit,

                    sx: {
                      borderRadius: "10px",
                      height: "40px",
                    },
                  }}
                  placeholder="PASSWORD"
                  type="password"
                  id="data.password"
                  {...formik.getFieldProps("data.password")}
                  error={
                    formik.touched.data?.password &&
                    Boolean(formik.errors.data?.password)
                  }
                  helperText={formik.errors.data?.password}
                />
              </Grid>
              {edit ? (
                <Grid
                  display="flex"
                  alignItems={formik.isValid ? "end" : "center"}
                  item
                >
                  <LoadingButton
                    sx={{ margin: 0, height: "40px" }}
                    onClick={() => formik.handleSubmit()}
                    loading={formik.isSubmitting}
                    disabled={formik.isSubmitting || !formik.isValid}
                    variant="contained"
                  >
                    Save
                  </LoadingButton>
                </Grid>
              ) : (
                <Grid display="flex" alignItems="end" item>
                  <Button
                    sx={{ margin: 0, height: "40px" }}
                    onClick={handleEdit}
                    variant="contained"
                  >
                    Edit
                  </Button>
                </Grid>
              )}

              <Grid item>
                {formik.errors.authError && (
                  <Alert severity="error">{formik.errors.authError}</Alert>
                )}
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </Box>
  );
};

export default GraphDBConfigurationComponent;
