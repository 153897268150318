import React from "react";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";

interface ContextMenuProps {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  handleCreateFile: () => void;
  handleCreateFolder: () => void;
  handleRename: () => void;
  handleDelete: () => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  anchorEl,
  handleClose,
  handleCreateFile,
  handleCreateFolder,
  handleRename,
  handleDelete,
}) => {
  const theme = useTheme();
  const blarCriticalColor = (theme.palette.blarCritical as any).main;

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
      <MenuItem onClick={handleCreateFile}>
        <ListItemIcon>
          <NoteAddIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Create File" />
      </MenuItem>
      <MenuItem onClick={handleCreateFolder}>
        <ListItemIcon>
          <CreateNewFolderIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Create Folder" />
      </MenuItem>
      <MenuItem onClick={handleRename}>
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Rename" />
      </MenuItem>
      <MenuItem onClick={handleDelete}>
        <ListItemIcon sx={{ color: blarCriticalColor }}>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>

        <ListItemText
          disableTypography
          primary={
            <Typography variant="body1" style={{ color: blarCriticalColor }}>
              Delete
            </Typography>
          }
        />
      </MenuItem>
    </Menu>
  );
};

export default ContextMenu;
