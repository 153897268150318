import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  chatContainer: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    width: "100%",
    overflow: "hidden",
  },
  sidebar: {
    flexGrow: 0,
    flexShrink: 0,
    minWidth: "25%",
    maxWidth: "35%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "100%",
    zIndex: 2,
  },
  chatBody: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    zIndex: 1,
    overflow: "hidden",
    padding: "10px",
  },
  sidebarContent: {
    flex: 1,
    border: 0,
  },
  sidebarResizer: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "6px",
    justifySelf: "flex-end",
    cursor: "col-resize",
    resize: "horizontal",
  }
}));

export default useStyles;
