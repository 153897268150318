import React, { useContext } from "react";
import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Context } from "@/contexts/ContextProvider";

interface CopyButtonProps {
  copyValue: string;
  size?: "small" | "medium" | "large";
  sx?: any;
  backgroundColor?: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({
  copyValue,
  size,
  sx,
  backgroundColor,
}) => {
  const { showMessage } = useContext(Context);

  const handleCopy = () => {
    navigator.clipboard.writeText(copyValue);
    showMessage("success", "Copied to clipboard");
  };

  return (
    <IconButton
      size={size}
      onClick={handleCopy}
      sx={{ backgroundColor: backgroundColor }}
    >
      <ContentCopyIcon sx={sx} />
    </IconButton>
  );
};

export default CopyButton;
