import { AlertColor } from "@mui/material";
import { URLSearchParams } from "url";
import { sentry_oauth } from "@/services/Blar/Integrations";

const REDIRECT_TIMEOUT = 3 * 1000;

export function redirectToSentryApp() {
  const SentryAuthUrl = 'https://blar-ai-inc.sentry.io/settings/integrations/?search=Blar';

  window.location.href = SentryAuthUrl;
}

const handleSentryIntegration = async (
  showMessage: (type: AlertColor, message: string) => void,
  searchParams: URLSearchParams
) => {
  try {
    const payload = {
      code: searchParams.get("code")!,
      installationId: searchParams.get("installationId")!,
      sentryOrgSlug: searchParams.get("orgSlug")!,
    };
    if (!payload.code || !payload.installationId || !payload.sentryOrgSlug) {
      showMessage("info", "Go to Sentry webpages to connect Sentry");
      return;
    }
    const response = await sentry_oauth(payload);
    const { redirect_url: redirectUrl, message } = response.data;
    showMessage("success", message);
    setTimeout(() => (window.location.href = redirectUrl), REDIRECT_TIMEOUT);
  } catch (error) {
    showMessage("error", "Failed to connect Sentry");
  }
};

export default handleSentryIntegration;
