import { useEffect, useState, useContext } from "react";
import { Typography, Grid, IconButton } from "@mui/material";
import { TableRowData } from "../../../../components/Tables/types";

import TableWithActions from "../../../../components/Tables/TableWithActions";
import AddIcon from "@mui/icons-material/Add";
import AddApiKeyModal from "./components/AddApiKey";
import { convertApiKeysToRow } from "./helper";
import { ApiKeyPaginatedResponse } from "./types";
import { Context } from "@/contexts/ContextProvider";
import { delete_api_key, list_api_keys } from "@/services/Blar/ApiKeys";

const defaultApiKeysTitles = ["Name", "API Key", "Active", "Created At"];

const ApiKeysTable = () => {
  const [openAddApiKey, setOpenAddApiKey] = useState(false);
  const [apiKeysRow, setApiKeysRow] = useState<TableRowData[]>([]);
  const [createdObject, setCreatedObject] = useState(Date.now());
  const [loading, setLoading] = useState<boolean>(false);
  const { showMessage } = useContext(Context);

  useEffect(() => {
    async function fetchApiKeysData() {
      try {
        setLoading(true);
        const apiKeysResult = (await list_api_keys())
          .data as ApiKeyPaginatedResponse;
        const apiKeysRows = convertApiKeysToRow(apiKeysResult);
        setApiKeysRow(apiKeysRows);
      } catch (error) {
        showMessage("error", `Failed to fetch Api Keys`);
      } finally {
        setLoading(false);
      }
    }
    fetchApiKeysData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdObject]);

  const handleAPiKeyDelete = async (index: number) => {
    try {
      await delete_api_key(apiKeysRow[index]._prefix.content as string);
      setCreatedObject(Date.now());
      showMessage("success", "Api Key deleted successfully");
    } catch (error) {
      showMessage("error", "Error deleting integration");
    }
  };

  const handleAddApiKey = () => {
    setOpenAddApiKey(true);
  };

  return (
    <>
      <Grid container alignItems="center" sx={{ marginBottom: "20px" }}>
        <Grid item>
          <Typography variant="h6">Api Keys</Typography>
        </Grid>
        <Grid item>
          <IconButton
            sx={{
              border: "1px solid #FFFFFF4D",
              color: "gray",
              marginLeft: "10px",
            }}
            size="small"
            onClick={handleAddApiKey}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
      <TableWithActions
        loading={loading}
        tableRows={apiKeysRow}
        onDelete={handleAPiKeyDelete}
        defaultTitles={defaultApiKeysTitles}
      />
      <AddApiKeyModal
        open={openAddApiKey}
        setCreatedObject={setCreatedObject}
        setOpen={setOpenAddApiKey}
      ></AddApiKeyModal>
    </>
  );
};

export default ApiKeysTable;
