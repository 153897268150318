import React from "react";
import { CircularProgress, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface TriggerProcessProps {
  triggerType: string;
};

const TriggerProcess: React.FC<TriggerProcessProps> = ({ triggerType }) => {
  const theme = useTheme();

  const triggerMessage = () => {
    if (triggerType === "codeerror") {
      return (
        <Typography variant="body1" textAlign={"start"} mt={2}>
          Blar is processing this error and will notify you when it's ready
        </Typography>
      );
    }
    if (triggerType === "pullrequest") {
      return (
        <Typography variant="body1" textAlign={"start"} mt={2}>
          Blar is reviewing your pull request and will notify you when it's ready
        </Typography>
      );
    }
    if (triggerType === "baseuser") {
      return (
        <Typography variant="body1" textAlign={"start"} mt={2}>
          Blar is working on your request. Wait a moment.
        </Typography>
      );
    }
  };

  return (
    <Paper
      sx={{
        borderColor: theme.palette.divider,
        maxWidth: 320,
        padding: 3,
      }}
    >
      <CircularProgress size={65} />
      {triggerMessage()}
    </Paper>
  );
};

export default TriggerProcess;
