import { useState, ChangeEvent, useContext } from "react";
import { TextField, Box, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BaseModal from "@/components/Modal/BaseModal";
import { save_api_key } from "@/services/Blar/Integrations";
import { IntegrationCardProps } from "../../pages/Settings/components/Integrations/types";
import { Context } from "@/contexts/ContextProvider";
type AddIntegrationModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  integration: IntegrationCardProps;
  setCreatedObject: (createdObject: number) => void;
};

const AddApiKeyModal = ({
  open,
  setOpen,
  integration,
  setCreatedObject,
}: AddIntegrationModalProps) => {
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState(false);
  const { showMessage } = useContext(Context);

  const handleClose = () => {
    setApiKey(""); // clear the input field when the modal is closed
    setOpen(false);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await save_api_key({
        api_key: apiKey,
        source: integration.dbName,
      });
      setCreatedObject(Date.now());
      showMessage("success", "API Key saved successfully");
      setLoading(false);
      handleClose();
    } catch (error) {
      showMessage("error", "Error saving API Key");
      setLoading(false);
    }
  };
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setApiKey(event.target.value);
  };

  return (
    <BaseModal
      open={open}
      onClose={handleClose}
      title={`Add ${integration.name} Integration`}
      style={{ width: "50%" }}
    >
      <Typography variant="subtitle1" sx={{ marginRight: "5px" }}>
        API Key:
      </Typography>
      <TextField
        hiddenLabel
        value={apiKey}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        variant="filled"
      />

      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={handleSave}
          disabled={!apiKey}
          sx={{ backgroundColor: "#1a7f64" }}
        >
          Save
        </LoadingButton>
      </Box>
    </BaseModal>
  );
};
export default AddApiKeyModal;
