import { WikiFile, WikiFolder } from "@/pages/Wiki/types";
import { blarClient } from "./Client";

// Get folder contents
export const getFolderContents = async (parentId: number | null = null) => {
  const response = await blarClient.request(
    "get",
    "/wiki/folder-contents/",
    null,
    {
      parent_id: parentId,
    }
  );
  return response.data;
};

// Get file content
export const getFileContent = async (fileId: number) => {
  const response = await blarClient.request("get", `/wiki/files/${fileId}/`);
  return response.data.content;
};

// Create folder
export const createFolder = async (name: string, parentId: number | null) => {
  const response = await blarClient.request("post", "/wiki/folders/", {
    name,
    parent: parentId,
  });
  return response.data;
};

// Create file
export const createFile = async (
  name: string,
  parentId: number | null,
  content: string | null = " "
) => {
  const response = await blarClient.request("post", "/wiki/files/", {
    name,
    folder: parentId,
    content,
  });
  return response.data;
};

// Update folder
export const updateFolder = async (folderId: number, folder: WikiFolder) => {
  const response = await blarClient.request(
    "put",
    `/wiki/folders/${folderId}/`,
    {
      name: folder.name,
      parent: folder.parent,
    }
  );
  return response.data;
};

// Update file
export const updateFile = async (fileId: number, file: WikiFile) => {
  const response = await blarClient.request("put", `/wiki/files/${fileId}/`, {
    name: file.name,
    content: file.content,
    folder: file.folder,
  });
  return response.data;
};

// Delete folder
export const deleteFolder = async (folderId: number) => {
  const response = await blarClient.request(
    "delete",
    `/wiki/folders/${folderId}/`
  );
  return response.data;
};

// Delete file
export const deleteFile = async (fileId: number) => {
  const response = await blarClient.request("delete", `/wiki/files/${fileId}/`);
  return response.data;
};
