import { Agent, ChatRoomResponse, InputTag } from "./components/types";
import {
  AgentStep,
  AgentStepType,
  FileNode,
  Message,
} from "./components/types";
import { Box, Paper } from "@mui/material";
import { ListChatRoomFilters, TriggerType } from "@/interfaces/IChatRoom";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  SendMessageParams,
  SendTagParams,
  get_chat_room_messages,
  mark_as_read,
  process_message_chunk,
  send_message,
} from "@/services/Blar/Chat";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { trackEvent } from "@/helpers/analytics";

import ChatBody from "./components/Chat/ChatBody";
import ChatMenu from "./components/Chat menu/ChatMenu";
import { Context } from "@/contexts/ContextProvider";
import Inbox from "./components/Inbox/Inbox";
import NewChatMenu from "./components/Chat menu/NewChatMenu";
import { checkSyncingRepos } from "@/services/Blar/Repo_graph";
import { connectWebSocket } from "@/services/Websocket/connect";
import { TagifyProvider } from "./context/TagifyContext";
import { useNotifications } from "@/contexts/NotificationContextProvider";
import useStyles from "./styles/Chat";
import { useTheme } from "@mui/material/styles";
import { w3cwebsocket } from "websocket";
import { ChatRoomContextProvider } from "@/contexts/ChatRoomContextProvider";

const defaultFilters: ListChatRoomFilters = {
  code_error_priority: [],
  code_error_state: [],
};

const defaultInboxFilters: ListChatRoomFilters = {
  code_error_priority: [],
  code_error_state: ["open", "acknowledged"],
  code_error_assigned_to: "me",
};

const defaultSentryFilters: ListChatRoomFilters = {
  code_error_priority: [],
  code_error_state: ["open", "acknowledged"],
  code_error_assigned_to: "all",
  trigger_type: "codeerror",
};

const Chat: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { showMessage, access, refresh } = useContext(Context);
  const [syncing, setSyncing] = useState(false);
  const socketRef = useRef<w3cwebsocket | null>(null);

  const sidebarRef = useRef<HTMLDivElement>(null);
  const closeChatRef = useRef(false);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(300);
  const { chatRoomId } = useParams<{ chatRoomId: string }>();
  const chatRoomIdNumber = chatRoomId ? parseInt(chatRoomId, 10) : null;
  const selectedChatRoomRef = useRef(chatRoomIdNumber);
  const [refreshChatRoomsTimestamp, setRefreshChatRoomsTimestamp] =
    useState<number>(0);
  const [selectedAgent, setSelectedAgent] = useState<Agent | undefined>(
    undefined
  );
  const [selectedNode, setSelectedNode] = useState<FileNode | undefined>(
    undefined
  );
  const selectedNodeRef = useRef(selectedNode);

  const [messages, setMessages] = useState<Message[]>([]);
  const [chatRoomData, setChatRoomData] = useState<ChatRoomResponse>();
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [agentStep, setAgentStep] = useState<AgentStep | null>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { subscribeToNotifications, unsubscribeFromNotifications } =
    useNotifications();

  //Syncing logic

  const updateSyncing = async () => {
    try {
      const response = await checkSyncingRepos();

      const repos = response.data.repos;
      const areReposSyncing = repos.length !== 0;
      setSyncing(areReposSyncing);
      return areReposSyncing;
    } catch (error) {
      console.error(error);
      showMessage("error", "Error checking syncing repos");
      setSyncing(false);
      return false;
    }
  };

  const notificationSuscriber = (message: any) => {
    const action = message.data.action;
    if (action === "repo_synced") {
      updateSyncing();
    } else if (action === "refresh_chats") {
      setRefreshChatRoomsTimestamp(Date.now());
    } else if (action === "refresh_chat") {
      if (
        selectedChatRoomRef.current &&
        selectedChatRoomRef.current.toString() === message.data.chat_room_id
      ) {
        fetchMessages(selectedChatRoomRef.current);
      }
    }
  };

  useEffect(() => {
    subscribeToNotifications(notificationSuscriber);
    updateSyncing();
    if (chatRoomIdNumber) {
      connectChatRoomWebSocket(chatRoomIdNumber!);
    }

    return () => {
      unsubscribeFromNotifications(updateSyncing);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Filters
  const triggerType = queryParams.get("triggerType") as TriggerType;
  let initialFilters = defaultFilters;
  if (triggerType) {
    if (triggerType === "baseuser") {
      initialFilters = { ...defaultFilters, trigger_type: "baseuser" };
    }
    if (["sentry"].includes(triggerType)) {
      initialFilters = {
        ...defaultSentryFilters,
        code_error_source: triggerType,
      };
    }
  }

  const [filters, setFilters] = useState<ListChatRoomFilters>(initialFilters);

  // TODO: Separate to a different component
  const startResizing = React.useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = React.useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = React.useCallback(
    (mouseMoveEvent: MouseEvent) => {
      if (isResizing && sidebarRef.current) {
        setSidebarWidth(
          mouseMoveEvent.clientX -
            sidebarRef.current.getBoundingClientRect().left
        );
      }
    },
    [isResizing]
  );

  const updateChatRoomIdInUrl = (chatRoomId: number | null) => {
    if (chatRoomId !== null) {
      navigate(`/chat/${chatRoomId}`);
    } else {
      navigate(`/chat`);
    }
  };

  const handleChooseStartingPoint = React.useCallback((node: FileNode) => {
    setSelectedNode(node);
  }, []);

  const connectChatRoomWebSocket = (chatRoomId: number) => {
    if (socketRef.current) {
      closeChatRef.current = true;
      socketRef.current.close(1000, "Switching chat rooms");
    }

    const chatUrl = `${process.env.REACT_APP_WS_URL}/chat/${chatRoomId}/?token=${access}&refresh_token=${refresh}`;
    const onMessage = (event: any) => {
      try {
        const json_data = process_message_chunk(event.data);

        if (json_data.type === "info") {
          if (json_data.message?.message === "Agent Started") {
            setLoadingResponse(true);
          } else if (json_data.message?.message === "Agent Finished") {
            setLoadingResponse(false);
          }
        }

        if (json_data.type === AgentStepType.Function) {
          if (json_data.response && json_data.call === "get_code_by_id") {
            const hasCorrectFormat =
              json_data.response.node_name && json_data.response.file_path;
            if (hasCorrectFormat) {
              setAgentStep(json_data);
            }
          }
        }
        if (
          json_data.type === AgentStepType.AIM ||
          json_data.type === AgentStepType.User
        ) {
          if (json_data.message) {
            setMessages((prevMessages) => {
              return [...prevMessages, json_data.message!];
            });
            setAgentStep(null);
          }
        }
      } catch (error) {
        showMessage("error", "Error processing message");
      }
    };
    const onOpen = (socket: w3cwebsocket | null) => {
      socketRef.current = socket;
    };
    const onClose = (error: any) => {
      setAgentStep(null);
    };
    try {
      connectWebSocket(
        chatUrl,
        onMessage,
        onOpen,
        undefined,
        onClose,
        closeChatRef
      );
    } catch (error) {
      console.error(error);
      showMessage("error", "Error connecting to chat room");
    }

    updateChatRoomIdInUrl(chatRoomId);
    setAgentStep(null);
  };

  const handleRoomChange = React.useCallback(
    (chatRoomId: number) => {
      if (chatRoomId === null) {
        return;
      }
      trackEvent("Chat", "Open Chat", "User opened a chat");
      connectChatRoomWebSocket(chatRoomId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [access, refresh]
  );

  const handleClickNewChat = (
    event: React.MouseEvent<HTMLElement>,
    value: any
  ) => {
    if (syncing) {
      return showMessage(
        "warning",
        "Please wait for the current sync to finish"
      );
    }
    setSelectedAgent(undefined);
    setSelectedNode(undefined);
    navigate(`/chat/new`);
  };

  const handleOpenNewChat = React.useCallback(
    async (agent: Agent, node: FileNode) => {
      setSelectedAgent(agent);
      setSelectedNode(node);
      setMessages([]);
      trackEvent("Chat", "Create New Chat", "User created a new chat");
      try {
        const response = await send_message({
          node_ids: node ? [node.node_id] : [],
          agent: agent,
        });
        setRefreshChatRoomsTimestamp(Date.now());
        handleRoomChange(response.data.id);
      } catch (error) {
        console.error(error);
        showMessage("error", "Error starting chat");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    selectedNodeRef.current = selectedNode;
  }, [selectedNode]);

  const handleSend = React.useCallback(
    async (
      input: string,
      node_ids: string[],
      frontEndMessage: string | null
    ) => {
      setLoadingResponse(true);
      if (input === "") {
        return;
      }

      const newMessage = input;

      setAgentStep(null);
      let params: SendMessageParams;

      params = {
        message: newMessage,
        node_ids: selectedNodeRef.current
          ? [selectedNodeRef.current.node_id, ...node_ids]
          : [...node_ids],
        type: "blar_agent_message",
        front_end_message: frontEndMessage,
      };
      trackEvent("Chat", "Send Message", "User sent a message");

      setSelectedNode(undefined);

      try {
        if (socketRef.current?.readyState === WebSocket.OPEN) {
          socketRef.current.send(JSON.stringify(params));
        } else {
          // Handle the case where the WebSocket is not open
          setAgentStep(null);
          setLoadingResponse(false);
          showMessage("error", "WebSocket is not open. Cannot send message.");
        }
      } catch (error) {
        console.error(error);
        setAgentStep(null);
        setLoadingResponse(false);
        showMessage("error", "Error sending message");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedNode]
  );

  const handleSendTag = React.useCallback(
    async (input: string, tags: InputTag[]) => {
      setLoadingResponse(true);

      const newMessage = input;

      setAgentStep(null);
      let params: SendTagParams;
      params = {
        message: newMessage,
        tags: tags,
        type: "tag_message",
      };

      try {
        socketRef.current?.send(JSON.stringify(params));
      } catch (error) {
        setLoadingResponse(false);
        showMessage("error", "Error sending message");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedNode]
  );

  const fetchMessages = async (chatRoom?: number) => {
    if (chatRoom) {
      try {
        mark_as_read(chatRoom);
      } catch (error) {
        showMessage("error", "Error marking chat as read");
      }
    }

    try {
      const chatRoomResponse = await get_chat_room_messages(
        chatRoom || chatRoomIdNumber!
      );
      if (chatRoomResponse.task_state === "in_progress") {
        setLoadingResponse(true);
      } else {
        setLoadingResponse(false);
      }

      setMessages(chatRoomResponse.messages);
      setChatRoomData(chatRoomResponse);
    } catch (error) {
      showMessage("error", "Error fetching chat messages");
    }
  };

  useEffect(() => {
    selectedChatRoomRef.current = chatRoomIdNumber;
    setAgentStep(null);
    if (chatRoomIdNumber) fetchMessages();
    if (!chatRoomIdNumber) setChatRoomData(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRoomIdNumber]);

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const triggerType = queryParams.get("triggerType") as TriggerType;

    if (triggerType) {
      if (triggerType === "baseuser" || triggerType === "pullrequest") {
        setFilters({ ...defaultFilters, trigger_type: triggerType });
      }
      if (["sentry"].includes(triggerType)) {
        setFilters({
          ...defaultSentryFilters,
          code_error_source: triggerType,
        });
        return;
      }
    } else {
      setFilters(defaultInboxFilters);
    }
  }, [location.search]);

  const sideBarContent = () => {
    if (chatRoomIdNumber) {
      return (
        <Paper
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            justifyContent: "start",
          }}
        >
          <ChatRoomContextProvider chatRoomId={chatRoomIdNumber}>
            <ChatMenu
              handleChooseStartingPoint={handleChooseStartingPoint}
              handleClickNewChat={handleClickNewChat}
              chatRoomId={chatRoomIdNumber}
            />
          </ChatRoomContextProvider>
        </Paper>
      );
    } else if (chatRoomId === "new") {
      selectedChatRoomRef.current = null;
      return (
        <Paper
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            justifyContent: "start",
          }}
        >
          <NewChatMenu openNewChat={handleOpenNewChat} />
        </Paper>
      );
    }

    return (
      <Inbox
        filters={filters}
        setFilters={setFilters}
        onChatRoomChange={handleRoomChange}
        refreshChatRoomsTimestamp={refreshChatRoomsTimestamp}
        setRefreshChatRoomsTimestamp={setRefreshChatRoomsTimestamp}
        handleClickNewChat={handleClickNewChat}
      />
    );
  };

  return (
    <TagifyProvider>
      <Box className={classes.chatContainer}>
        <Box
          className={classes.sidebar}
          ref={sidebarRef}
          onMouseDown={(e) => e.preventDefault()}
          sx={{ height: "100%", width: sidebarWidth }}
        >
          <Box className={classes.sidebarContent}>{sideBarContent()}</Box>
          <Box
            className={classes.sidebarResizer}
            onMouseDown={startResizing}
            sx={{
              backgroundColor: theme.palette.background.paper,
              overflow: "hidden",
              border: 0,
            }}
          />
        </Box>

        <Box className={classes.chatBody}>
          <ChatBody
            selectedChatRoom={chatRoomIdNumber || null}
            chatRoomData={chatRoomData}
            loading={loadingResponse}
            setLoading={setLoadingResponse}
            messages={messages}
            agentStep={agentStep}
            sendMessage={handleSend}
            selectedAgent={selectedAgent}
            selectedNode={selectedNode}
            setChatRoomData={setChatRoomData}
            setMessages={setMessages}
            setRefreshChatRoomsTimestamp={setRefreshChatRoomsTimestamp}
            handleSendTag={handleSendTag}
            syncing={syncing}
            triggerType={triggerType || chatRoomData?.trigger_type}
          />
        </Box>
      </Box>
    </TagifyProvider>
  );
};

export default Chat;
