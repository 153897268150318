import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import { DataType, TablePaginationOptions, TableRowData } from "./types";
import { LinearProgress, Link } from "@mui/material";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
}));

const formatDate = (value: DataType) => {
  const dateFormat = value.options?.dateFormat || "dd MMMM yyyy";
  const dateContent = value.content as string;
  return format(new Date(dateContent), dateFormat);
};

const formatters: { [key in DataType["type"]]: (value: any) => any } = {
  string: (value) => value.content,
  number: (value) => value.content.toFixed(2),
  date: (value) => formatDate(value),
  boolean: (value) =>
    value.content ? (
      <CheckCircleIcon sx={{ color: "#1a7f64" }} />
    ) : (
      <CloseIcon sx={{ color: "#ef4146" }} />
    ),
  hidden: () => null,
  url: (value) => (
    <Link href={value.content} target="_blank" rel="noopener noreferrer">
      {value.content}
    </Link>
  ),
  react: (value) => value.content,
};

type TableWithActionsProps = {
  tableRows: TableRowData[];
  defaultTitles: string[];
  loading: boolean;
  onDelete?: (index: number) => void;
  onEdit?: (index: number) => void;
  tablePagination?: TablePaginationOptions;
};

const TableWithActions: React.FC<TableWithActionsProps> = ({
  tableRows,
  defaultTitles,
  loading,
  onDelete,
  onEdit,
  tablePagination,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowIndexToDelete, setRowIndexToDelete] = useState<number | null>(null);

  const tableTitles = tableRows[0] ? Object.keys(tableRows[0]) : defaultTitles;
  const visibleTitles = tableTitles.filter(
    (title) => tableRows[0]?.[title]?.type !== "hidden"
  );

  const handleDeleteClick = (index: number) => {
    setRowIndexToDelete(index);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (rowIndexToDelete !== null && onDelete) {
      onDelete(rowIndexToDelete);
      setIsModalOpen(false);
    }
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ padding: "0", maxHeight: '400px', overflowY: 'auto' }}>
        <Table size="small" sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {visibleTitles.map((title) => (
                <TableCell align="center" key={title}>
                  {title}
                </TableCell>
              ))}
              {onDelete && <TableCell align="right"></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={visibleTitles.length + 1}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              tableRows.map((row, index) => (
                <StyledTableRow key={index}>
                  {tableTitles.map(
                    (title) =>
                      row[title].type !== "hidden" && (
                        <StyledTableCell align="center" key={title}>
                          {formatters[row[title].type]
                            ? formatters[row[title].type](row[title])
                            : row[title].content}
                        </StyledTableCell>
                      )
                  )}
                  {onDelete && (
                    <StyledTableCell align="right">
                      <IconButton onClick={() => handleDeleteClick(index)}>
                        <DeleteIcon fontSize="small" sx={{ color: "#ef4146" }} />
                      </IconButton>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {tablePagination && (
        <TablePagination
          component="div"
          count={tablePagination.count}
          rowsPerPageOptions={[10, 20, 30]}
          page={tablePagination.page}
          rowsPerPage={tablePagination.rowsPerPage}
          onPageChange={tablePagination.handlePagination}
          onRowsPerPageChange={tablePagination.handleChangeRowsPerPage}
        />
      )}
      <ConfirmDeleteModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default TableWithActions;
