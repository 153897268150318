import ReactGA from "react-ga4";

export const initializeGA = (measurementId: string | undefined): void => {
  if (measurementId !== undefined) {
    ReactGA.initialize(measurementId);
  }
};

export const trackPageView = (page: string): void => {
  const basePath = `/${page.split("/")[1]}`; // Extracts the base path avoid disguising between chat/xxx and chat/yyy

  ReactGA.send({ hitType: "pageview", page: basePath });
};

export const trackEvent = (
  category: string,
  action: string,
  label: string
): void => {
  ReactGA.event({ category, action, label });
};
