import { useState } from "react";
import { Box, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import BaseModal from "@/components/Modal/BaseModal";

type ConfirmDeleteModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
};

const ConfirmDeleteModal = ({
  open,
  setOpen,
  onConfirm,
}: ConfirmDeleteModalProps) => {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleClose();
  };

  const handleConfirm = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setLoading(true);
    try {
      await onConfirm();
      handleClose();
    } catch (error) {
      console.error("Error during confirmation:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal
      open={open}
      onClose={handleClose}
      title={"Confirm Delete"}
      style={{ width: "40%" }}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Are you sure you want to delete this item?
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <LoadingButton
            onClick={handleCancelClick}
            variant="outlined"
            sx={{ mr: 2 }}
            disabled={loading}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            onClick={handleConfirm}
            variant="contained"
            loading={loading}
            sx={{ backgroundColor: "#ef4146" }}
          >
            Confirm
          </LoadingButton>
        </Box>
      </Box>
    </BaseModal>
  );
};

export default ConfirmDeleteModal;
