import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { Repository } from "@/interfaces/IBackendOutputs";
import { AxiosResponse } from "axios";
import { github_list_repo_branches, github_list_repos, github_repository_info } from "@/services/Blar/Integrations";
import { Context } from "@/contexts/ContextProvider";
import { LoadingButton } from "@mui/lab";
import GitHubIcon from "@mui/icons-material/GitHub";
import { CreateRepoParams } from "@/pages/Settings/components/Repos/types";
import { create_repo } from "@/services/Blar/Repo_graph";

interface RepositoryBranchSelectorProps {
  setHasRepo: React.Dispatch<React.SetStateAction<boolean>>;
}

const RepositoryBranchSelector: React.FC<RepositoryBranchSelectorProps> = ({ setHasRepo }) => {
  const { showMessage } = useContext(Context);
  const [repositories, setRepositories] = useState<Repository[]>([]);
  const [branches, setBranches] = useState<string[]>([]);
  const [selectedRepository, setSelectedRepository] = useState<number>(-1);
  const [selectedMainBranch, setSelectedMainBranch] = useState<number>(-1);
  const [selectedDevBranch, setSelectedDevBranch] = useState<number>(-1);
  const [loadingRepos, setLoadingRepos] = useState(false);
  const [isRepositoryTooBig, setIsRepositoryTooBig] = useState(false);
  const [repositoryPage, setRepositoryPage] = useState(1);
  const [branchesPage, setBranchesPage] = useState(1);
  const [hasMoreRepos, setHasMoreRepos] = useState(true);
  const [hasMoreBranches, setHasMoreBranches] = useState(true);

  const fetchNextDataRepository = useCallback(async (page: number) => {
    try {
      setLoadingRepos(true);
      const repositoriesResponse: AxiosResponse = await github_list_repos(page);
      if (repositoriesResponse.status === 200) {
        setRepositories((prev) => [...prev, ...repositoriesResponse.data.results]);
        setRepositoryPage(repositoriesResponse.data.page + 1);
        setHasMoreRepos(repositoriesResponse.data.total_count > repositories.length);
      }
    } catch (error) {
      showMessage("error", "Failed to get repositories");
    } finally {
      setLoadingRepos(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repositories]);

  useEffect(() => {
    fetchNextDataRepository(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchNextDataBranches = useCallback(async (repo: string, page: number) => {
    try {
      setLoadingRepos(true);
      const branchesResponse: AxiosResponse = await github_list_repo_branches(repo, page);
      if (branchesResponse.status === 200) {
        setBranches((prev) => [...prev, ...branchesResponse.data.results]);
        setBranchesPage(branchesResponse.data.page + 1);
        setHasMoreBranches(branchesResponse.data.total_count > branches.length);
      }
    } catch (error) {
      showMessage("error", "Failed to get branches");
    } finally {
      setLoadingRepos(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches]);

  useEffect(() => {
    // get repository branches
    setBranchesPage(1);
    setBranches([]);
    if (selectedRepository !== -1) {
      fetchNextDataBranches(repositories[selectedRepository].fullName, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repositories, selectedRepository]);


  const handleRepositoryChange = async (
    event: SelectChangeEvent<number | null>
  ) => {
    const value = parseInt(event.target.value as string, 10);
    if (isNaN(value)) {
      return;
    }
    
    setSelectedRepository(value);
    const repositoryName = repositories[value].fullName;
    const org = repositoryName.split("/")[0];
    const repo = repositoryName.split("/")[1];
    try {
      const repositoryInfoRequest = await github_repository_info(org, repo);
      const repositoryInfo = repositoryInfoRequest.data;
      const size = repositoryInfo.size;
      // check if the repository is too big, 2GB
      if (size > 2000000) {
        setIsRepositoryTooBig(true);
      } else {
        setIsRepositoryTooBig(false);
      }
    } catch (error) {}
  };

  const handleMainBranchChange = (event: SelectChangeEvent<number | null>) => {
    setSelectedMainBranch(event.target.value as number);
  };

  const handleDevBranchChange = (event: SelectChangeEvent<number | null>) => {
    setSelectedDevBranch(event.target.value as number);
  };

  const handleAddRepository = async () => {
    const allReady = selectedRepository !== -1 && 
      selectedMainBranch !== -1 &&
      selectedDevBranch !== -1;

    if (allReady) {
      try {
        setLoadingRepos(true);
        const addRepoParams: CreateRepoParams = {
          github_url: repositories[selectedRepository].url,
          main_branch: branches[selectedMainBranch],
          development_branch: branches[selectedDevBranch],
        };
        const createRepoResponse = await create_repo(addRepoParams);
        console.log(createRepoResponse);
        if (createRepoResponse.status === 201) {
          showMessage("success", "Repository added successfully");
          setHasRepo(true);
        }
        setSelectedRepository(-1);
      } catch (error) {
        showMessage("error", "Failed to add repository");
      } finally {
        setLoadingRepos(false);
      }
    }
  };

  return (
    <Box width={"100%"}>
      <FormControl fullWidth>
        <InputLabel id="repository_select" style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>Repository</InputLabel>
        <Select
          id="repository_select"
          color="secondary"
          value={selectedRepository}
          label="Repository"
          placeholder="Select a repository"
          onChange={handleRepositoryChange}
          MenuProps={{
            PaperProps: {
              onScroll: (event: React.UIEvent<HTMLElement>) => {
                const bottom =
                  event.currentTarget.scrollHeight ===
                  event.currentTarget.scrollTop + event.currentTarget.clientHeight;
                if (bottom && hasMoreRepos && !loadingRepos) {
                  fetchNextDataRepository(repositoryPage);
                }
              },
              style: { maxHeight: 300, overflowY: "auto" },
            },
          }}
        >
          {repositories.map((repo, index) => (
            <MenuItem key={index} value={index}>
              {repo.fullName}
            </MenuItem>
          ))}
        </Select>
        <br/>
      </FormControl>
      
      {isRepositoryTooBig && (
        <Typography variant="body1" color="error">
          Currently, we don't support repositories bigger than 2GB. Please select another one.
        </Typography>
      )}
      
      {selectedRepository !== -1 && (
        <>
          <Typography variant="body2" color="textSecondary" style={{ marginTop: 8 }}>
            This branch is used to answer questions in the general chat feature.
          </Typography>
         <br></br>
          <FormControl fullWidth>
            <InputLabel id="dev_branch_select" style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
              Production branch
            </InputLabel>
            
            <Select
              id="branch_select"
              value={selectedMainBranch}
              label="Branches"
              placeholder="Select a branch"
              onChange={handleMainBranchChange}
              MenuProps={{
                PaperProps: {
                  onScroll: (event: React.UIEvent<HTMLElement>) => {
                    const bottom =
                      event.currentTarget.scrollHeight ===
                      event.currentTarget.scrollTop + event.currentTarget.clientHeight;
                    if (bottom && hasMoreBranches && !loadingRepos) {
                      fetchNextDataBranches(repositories[selectedRepository].fullName, branchesPage);
                    }
                  },
                  style: { maxHeight: 300 },
                },
              }}
            >
              {branches.map((branch, index) => (
                <MenuItem key={index} value={index}>
                  {branch}
                </MenuItem>
              ))}
            </Select>
            
            <br />
          </FormControl>
          
          <Typography variant="body2" color="textSecondary" style={{ marginTop: 8, fontSize: '0.9rem' }}>
            This branch is used to review incoming changes in a Pull Request.
          </Typography>
          <br></br>
          <FormControl fullWidth>
          <InputLabel id="dev_branch_select" style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
            Pull Request Review branch
          </InputLabel>
          
            <Select
              id="dev_branch_select"
              value={selectedDevBranch}
              label="Branches"
              placeholder="Select a branch"
              onChange={handleDevBranchChange}
              MenuProps={{
                PaperProps: {
                  onScroll: (event: React.UIEvent<HTMLElement>) => {
                    const bottom =
                      event.currentTarget.scrollHeight ===
                      event.currentTarget.scrollTop + event.currentTarget.clientHeight;
                    if (bottom && hasMoreBranches && !loadingRepos) {
                      fetchNextDataBranches(repositories[selectedRepository].fullName, branchesPage);
                    }
                  },
                  style: { maxHeight: 300 },
                },
              }}
            >
              {branches.map((branch, index) => (
                <MenuItem key={index} value={index}>
                  {branch}
                </MenuItem>
              ))}
            </Select>
            
            <br />
          </FormControl>
          
        </>
      )}
      <LoadingButton
        variant="contained"
        color="secondary"
        startIcon={<GitHubIcon />}
        style={{ fontSize: "20px", margin: 0 }}
        onClick={handleAddRepository}
        loading={loadingRepos}
        disabled={
          selectedRepository === -1 ||
          selectedMainBranch === -1 ||
          selectedDevBranch === -1 ||
          isRepositoryTooBig
        }
      >
        Add Repository
      </LoadingButton>
    </Box>
  );
};

export default RepositoryBranchSelector;