import React, { createContext, useRef, useContext, ReactNode } from "react";

type TagifyContextType = React.MutableRefObject<any | null>;

const TagifyContext = createContext<TagifyContextType | null>(null);

export const useTagify = (): TagifyContextType => {
  const context = useContext(TagifyContext);
  if (!context) {
    throw new Error("useTagify must be used within a TagifyProvider");
  }
  return context;
};

interface TagifyProviderProps {
  children: ReactNode;
}

export const TagifyProvider: React.FC<TagifyProviderProps> = ({ children }) => {
  const tagifyRef = useRef<any | null>(null);

  return (
    <TagifyContext.Provider value={tagifyRef}>
      {children}
    </TagifyContext.Provider>
  );
};
