import { ThemeOptions } from "@mui/material";
import { createTheme, PaletteColorOptions } from "@mui/material/styles";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: any) =>
  augmentColor({ color: { main: mainColor } });

declare module "@mui/material/Box" {
  interface BoxPropsColorOverrides {
    secondaryBackground: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    blarBlack: true;
    deactivated: true;
    blarWhite: true;
    blarSuccess: true;
  }
}

declare module "@mui/material/Badge" {
  interface BadgePropsColorOverrides {
    blarBlack: true;
    deactivated: true;
    blarWhite: true;
    blarCritical: true;
    blarHigh: true;
    blarWarning: true;
    blarSuccess: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    blarBlack: true;
    deactivated: true;
    blarWhite: true;
    blarCritical: true;
    blarHigh: true;
    blarWarning: true;
    blarSuccess: true;
  }
}
declare module "@mui/material/MenuItem" {
  interface MenuItemPropsColorOverrides {
    blarBlack: true;
    deactivated: true;
    blarWhite: true;
    blarCritical: true;
    blarHigh: true;
    blarWarning: true;
    blarSuccess: true;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    blarBlack: true;
    deactivated: true;
    blarWhite: true;
    blarCritical: true;
    blarHigh: true;
    blarWarning: true;
    blarSuccess: true;
  }
}

declare module "@mui/material/styles" {
  interface CustomPalette {
    blarBlack: PaletteColorOptions;
    deactivated: PaletteColorOptions;
    blarWhite: PaletteColorOptions;
    blarCritical: PaletteColorOptions;
    blarHigh: PaletteColorOptions;
    blarWarning: PaletteColorOptions;
    blarSuccess: PaletteColorOptions;
    secondaryBackground: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

const GeneralTheme: ThemeOptions = {
  palette: {
    primary: {
      main: "#D6D7D8",
    },
    secondary: {
      main: "#4593F8",
    },
    background: {
      default: "#090C13",
      paper: "#161B22",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "rgba(255, 255, 255)",
    },
    action: {
      active: "#D6D7D8",
      disabled: "#FFFFFF",
    },
    info: {
      main: "#FFFFFF",
    },
    divider: "#30363D",
    blarBlack: createColor("#1B293C"),
    deactivated: createColor("#1B293C80"),
    blarWhite: createColor("#E8EFF5"),
    blarCritical: createColor("#FF6371"),
    blarHigh: createColor("#EFA00B"),
    blarWarning: createColor("#FFEE70"),
    blarSuccess: createColor("#36CF8F"),
    secondaryBackground: createColor("#0D1116"),
  },
  typography: {
    fontFamily: '"Ubuntu", "Helvetica", "sans-serif"',
    h6: {
      fontWeight: 500,
    },
    h3: {
      fontWeight: 700,
    },
    body1: {
      fontFamily: "Ubuntu Sans Mono",
      color: "#FFFFFF",
    },
    body2: {
      color: "#FFFFFF",
    },
    caption: {
      fontFamily: "Ubuntu Sans Mono",
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#D6D7D880 #161B22",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            "-webkit-appearance": "none",
            backgroundColor: "#161B22",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#D6D7D880",
            minHeight: 24,
            border: "3px solid #161B22",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#161B22",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            padding: "8px",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          margin: "8px",
          borderRadius: "5px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: "10px",
          borderRadius: "5px",
          boxShadow: "none",
          backgroundImage: "none",
          border: "1px solid",
          borderColor: palette.divider,
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            background: "#34383E",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          border: "none",
          padding: "5px",
          "&:before": {
            height: 0,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#5E646A",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:not(.Mui-disabled):before":
            {
              borderBottomColor: "#5E646A", // Set underline color to black
            },
          "& .MuiInputBase-input": {
            color: "#FFFFFF", // Set text color to black
            "&:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 100px #161B22 inset",
              WebkitTextFillColor: "#FFFFFF",
            },
          },
          "& .MuiInputLabel-root": {},
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#5E646A", // Set label text color to black when focused
          },
          "& .MuiInput-underline:before": {
            borderBottomColor: "#5E646A", // Set underline color to black
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#5E646A", // Set underline color to black
          },
          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottomColor: "#5E646A", // Set underline color to black on hover
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#5E646A",
          },
        },
      },
    },
  },
};

export default GeneralTheme;
